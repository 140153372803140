import { useCMSPage } from '~/libs/queries/bff';
import { ModuleList, ModuleRow } from '~/features/primitives/modules';
import { DynamicWidget } from '~/widgets/dynamic-widget';

export default function StandardPage() {
    const { data: { modules = [] } = {} } = useCMSPage();

    return (
        <ModuleList gap="default">
            {modules.map((item) => {
                return (
                    <ModuleRow key={item.id} spacing={item.spacingBottom}>
                        <DynamicWidget widget={item} />
                    </ModuleRow>
                );
            })}
        </ModuleList>
    );
}
